import { TFunction } from 'react-i18next';
import * as yup from 'yup';
import 'yup-phone';

import {
  answerSchema,
  cguConsentSchema,
  emailSchema,
  firstNameSchema,
  lastNameSchema,
  passwordSchema,
  phoneCellphoneSchema,
  questionSchema,
  slotSchema
} from './shared';

export type TFunctionTranslationFormValidation = TFunction<'form', undefined>;

export const profileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t),
      secondaryEmail: yup.string().email(t('schema.email.valid'))
    })
    .required();

export const addCollaboratorSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    memberEmail: emailSchema(t)
  });

export const addPartnerCollaboratorSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    email: emailSchema(t),
    firstName: firstNameSchema(t),
    lastName: lastNameSchema(t)
  });

export const addBulkPartnerCollaboratorSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    file: yup.object()
  });

export const contactSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    phone: phoneCellphoneSchema(t)
  });

export const contactProSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    email: emailSchema(t),
    phone: phoneCellphoneSchema(t),
    companyName: yup.string().required(),
    jobTitle: yup.string()
  });

export const funnelQuestionSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    question: questionSchema(t),
    source: yup.mixed().oneOf(['personal', 'cse']).required()
  });

export const additionalRequestSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    question: questionSchema(t)
  });

export const consultationSlotSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    slot: slotSchema(t)
  });

export const questionAnswerSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    answerContent: answerSchema(t)
  });

export const additionalRequestAnswerSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    answerContent: answerSchema(t)
  });

export const cancelConsultationIntentSchema = (
  t: TFunctionTranslationFormValidation
) =>
  yup.object({
    intent: yup.string().required()
  });

export const finishProfileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t)
    })
    .required();

export const completeProfileSchema = (t: TFunctionTranslationFormValidation) =>
  yup
    .object({
      firstName: firstNameSchema(t),
      lastName: lastNameSchema(t),
      phoneCellphone: phoneCellphoneSchema(t),
      cguConsent: cguConsentSchema(t),
      newsletterConsent: yup.boolean().notRequired(),
      secondaryEmail: yup.string().email(t('schema.email.valid')).notRequired()
    })
    .required();

// Schemas for authentified user
export const authNewPasswordSchema = (t: TFunctionTranslationFormValidation) =>
  yup.object({
    password: passwordSchema(t),
    passwordConfirmation: passwordSchema(t).oneOf(
      [yup.ref('password'), null],
      'Passwords must match'
    )
  });
