import { Navigate, Route, Routes } from 'react-router-dom';

import LoginMagicLinkPage from 'pages/LoginMagicLinkPage';
import LoginPage from 'pages/LoginPage';
import RequestResetPasswordPage from 'pages/RequestResetPasswordPage';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import {
  ConsultationAppointmentVotePage,
  NotFoundErrorPage,
  NpsVotePage,
  PrivateContentPage,
  QuestionAnswerVote
} from 'pages/customer';
import { SignupPage } from 'pages/customer/onboarding';

import { PUBLIC_DEFAULT_REDIRECT_URL } from './constants';

const PublicRouter = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="authenticate" element={<LoginMagicLinkPage />} />
    <Route
      path="questions/vote/link/:voteLinkId"
      element={<QuestionAnswerVote />}
    />
    <Route
      path="consultations/vote/link/:voteLinkId"
      element={<ConsultationAppointmentVotePage />}
    />
    <Route path="nps/vote/:accountId" element={<NpsVotePage />} />
    <Route path="/signup" element={<SignupPage />} />
    <Route path="password/request" element={<RequestResetPasswordPage />} />
    <Route path="password/new" element={<ResetPasswordPage />} />
    <Route path="content/:slug" element={<PrivateContentPage />} />
    <Route path="*" element={<NotFoundErrorPage />} />
    <Route
      path=""
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
    <Route
      path="account/*"
      element={<Navigate to={PUBLIC_DEFAULT_REDIRECT_URL} replace />}
    />
    <Route
      path="onboarding/email"
      element={<Navigate to="/signup" replace />}
    />
  </Routes>
);

export default PublicRouter;
