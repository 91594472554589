import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_REDIRECT_URL } from 'constants/customer';
import { useErrorHandler } from 'react-error-boundary';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import {
  AuthResetPasswordPayload,
  resetPassword
} from 'services/authentication/password';

import { useGuardedSearchParams } from 'hooks';

import { useAuth } from 'context';

import { Button } from 'features/shared/buttons';
import { TextInputGroup } from 'features/shared/forms';
import { LayoutCard } from 'features/shared/layout';

import { resetPasswordSchema } from './validation';

export interface ResetPasswordFormInputs {
  password: string;
  token: string;
  confirmPassword: string;
}

const ResetPassword = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('customer');
  const { t: tForm } = useTranslation('form');
  const handleError = useErrorHandler();
  const token = useGuardedSearchParams({ param: 'token' });
  const { setEmail } = useAuth();

  const { mutate, isLoading } = useMutation(
    (formData: AuthResetPasswordPayload) => resetPassword(formData),
    {
      onSuccess: (data) => {
        setEmail(data.email);
        navigate(DEFAULT_REDIRECT_URL);
      }
    }
  );

  const {
    register,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<ResetPasswordFormInputs>({
    defaultValues: { token },
    resolver: yupResolver(resetPasswordSchema(tForm))
  });

  const onSubmit: SubmitHandler<ResetPasswordFormInputs> = async (formData) => {
    mutate(formData, {
      onError: (error: any) => {
        if (error?.response?.status === 401) {
          return setError('root', { message: 'password.reset.error' });
        }

        return handleError(error);
      }
    });
  };

  return (
    <LayoutCard
      title={t('password.reset.new.title')}
      body={
        <div className="w-full">
          {errors.root && (
            <Trans
              parent="p"
              ns="customer"
              className="mt-2 text-red-800 mb-4"
              i18nKey="password.reset.error"
              components={{
                1: <Link className="underline" to="/password/request" />
              }}
            />
          )}
          <div className="pb-4">
            <p>
              {t('password.new.p.1')} <br /> {t('password.new.p.2')}
            </p>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex w-full flex-col gap-4"
          >
            <TextInputGroup
              autofocus
              fullWidth
              type="password"
              placeholder="**********"
              label={tForm('label.password')}
              name="password"
              register={register}
              error={errors.password}
            />
            <TextInputGroup
              name="confirmPassword"
              label={`${tForm('label.confirmPassword')}`}
              type="password"
              register={register}
              error={errors.confirmPassword}
              placeholder="**********"
            />
            <Button
              isLoading={isLoading}
              fullWidth
              submit
              size="medium"
              variant="primary"
              label={t('general.continue')}
            />
          </form>
        </div>
      }
    />
  );
};

export default ResetPassword;
